<template>
  <div class="Examine">
    <div>
      <el-select v-if="$store.state.Login.Jurisdiction == 0" size="medium" v-model="Factory" style="width: 110px; padding: 0px 0px 0px 10px;" clearable :placeholder="$t('system.选择厂区')">
        <el-option
          v-for="item in $PublicJs.factory"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-input size="medium" clearable v-model="text" onkeyup="value=value.replace(/[^a-zA-Z0-9]/g,'').toUpperCase()" @blur="text=text.replace(/[^a-zA-Z0-9]/g,'').toUpperCase()" oninput="if (value.trim() === '') { value = null; }" @keyup.enter="Butsele()" style="width: 200px; padding: 0px 10px 0px 10px;" :placeholder="$t('All.料号')"></el-input>
      <el-button type="primary" icon="el-icon-search" size="small" @click="Butsele()">{{this.$t('All.搜索')}}</el-button>
      <el-button  size="small" @click="dataadd()" type="warning">{{this.$t('system.新增')}}</el-button>
    </div>
    <div>
      <template>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column align="center" :label="this.$t('system.序号')" type="index" width="90">
          </el-table-column>
          <el-table-column align="center" prop="Date" :label="this.$t('system.创建日期')" width="180">
          </el-table-column>
          <el-table-column align="center" prop="Item_No" :label="this.$t('system.料号')">
          </el-table-column>
          <el-table-column align="center" prop="Enteredby" :label="this.$t('system.lururen')" >
          </el-table-column>
          <el-table-column align="center" :label="this.$t('system.操作')" width="100" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" @click="AdminDataSelect(scope.row.Item_No)" type="primary">Review</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <el-pagination
      style="text-align: center;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageindex"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      tableData: [],
      total: 0,
      page_size: 10,
      pageindex: 1,
      text: '',
      Factory: ''
    }
  },
  created () {
    this.IQCAdminData()
  },
  props: ['Addtab', 'RemoveTab', 'Renewtargename', 'Rendw'],
  mounted () {

  },
  methods: {
    ...mapMutations(['UserData', 'NewIQCAdminNumberFun', 'Productname']),
    async IQCAdminData () {
      const fqcdata = {
        Text: this.text,
        Factory: this.Factory,
        Pageindex: this.pageindex,
        PageSize: this.page_size,
        UserData: this.$store.state.Login
      }
      const { data: res } = await this.$http.post('/api/Newiqc/NewIQCAdminData', fqcdata)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.total = res.response.TotalCount
      this.tableData = res.response.NewIQCadmin
    },
    async Butsele () {
      this.pageindex = 1
      this.page_size = 10
      this.IQCAdminData()
    },
    handleSizeChange (val) {
      this.page_size = val
      this.IQCAdminData()
    },
    handleCurrentChange (val) {
      this.pageindex = val
      this.IQCAdminData()
    },
    dataadd () {
      this.Addtab(this.$t('All.新增') + this.$t('All.新IQC模版'), 'NewIQCSeleItemAdmin')
    },
    async AdminDataSelect (row) {
      const postdata = {
        Number: row,
        UserData: this.$store.state.Login
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Newiqc/NewIQCSelectadmin', postdata)
        if (res.status !== 200) {
          loadingInstance.close()
          this.QcCount()
          this.$nextTick(() => { // 异步关闭
            this.UserData(null)
            this.Productname(this.Noitem)
            this.Addtab(this.$t('All.新IQC模版') + '-' + this.$store.state.NewIQCAdminNumber, 'NewIQCAdmin')
            this.$message.success(this.$t('All.数据不存在') + ',' + this.$t('All.即将进入编辑页面'))
          })
        }
        loadingInstance.close()
        this.UserData(res.response)
        this.Addtab(this.$t('All.新IQC模版') + '-' + res.response.Number, 'NewIQCAdmin')
      } catch (error) {
        loadingInstance.close()
        this.$message.error(error)
      }
    },
    async QcCount () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const QcCountLogin = {
          Login: this.$store.state.Login,
          Type: 'IQC'
        }
        const { data: res } = await this.$http.post('/api/User/QcCount', QcCountLogin)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.response)
        }
        this.NewIQCAdminNumberFun(res.response)
        loadingInstance.close()
      } catch (error) {
        loadingInstance.close()
        console.log(error)
        alert(this.$t('All.网络异常'))
      }
    }
  }
}
</script>
<style lang="less" scoped>

</style>
